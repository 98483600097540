export enum SmartAirSelections {
  CustomerFlow = 'Measured Fuel Flow & Air Flow Model',
  CleanTip = 'Measured Fuel Pressure & Air Flow Model',
  MeasuredO2 = 'Measured O<sub>2</sub>',
  fuelPressure = 'Fuel Pressure',
  measuredFuelFlow = 'Measured Fuel Flow',
  measuredO2 = 'Measured O2',
  MeasuredO2FixedFuel='Measured O<sub>2</sub> & Fuel Side Model',
  MeasuredO2FixedAir='Measured O<sub>2</sub> & Fixed Air Model',
}

export enum SmartAirMethodToUse {
  fuelPressure = 'CleanTip',
  measuredFuelFlow = 'CustomerFlow',
  measuredO2 = 'MeasuredO2',
  FuelPressure = 'CleanTip',
  MeasuredFuelFlow = 'CustomerFlow',
  MeasuredO2 = 'MeasuredO2',
  MeasuredO2FixedFuel='MeasuredO2FixedFuel',
  MeasuredO2FixedAir='MeasuredO2FixedAir',
}

export enum SmartAirDisplayValues {
  CleanTip = 'FuelPressure',
  CustomerFlow = 'MeasuredFuelFlow',
  FuelPressure = 'Fuel Pressure',
  MeasuredFuelFlow = 'Measured Fuel Flow',
  MeasuredO2 = 'MeasuredO2',
  MostConservative = 'MostConservative',
  MostConservativeO2 = 'Most Conservative O<sub>2</sub>',
  MeasuredO2FixedFuel='MeasuredO2FixedFuel',
  MeasuredO2FixedAir='MeasuredO2FixedAir',
}

export enum SmartAirProjectedNames {
  FuelPressure = 'fuelPressure',
  MeasuredFuelFlow = 'measuredFuelFlow',
  MeasuredO2 = 'measuredO2',
  MeasuredO2FixedFuel='measuredO2FixedFuel',
  MeasuredO2FixedAir='measuredO2FixedAir',
}
