export enum TransactionName {
    Landing = 'Landing',
    ZoneChange = 'ZoneChange',
    MethodChange = 'MethodChange',
    SaveMethod = 'SaveMethod',
    InitialPageLoad = 'InitialPageLoad',
    CloseSmartAirPopup = 'CloseSmartAirPopup',
    LoadSettingsData = 'LoadSettingsData',
    EmberRunTriggered = 'EmberRunTriggered',
    ReportingDataCall = 'ReportingDataCall',
    LoadZone = 'LoadZone'
}
