import { AxiosInstance } from 'axios';
import BaseService from '@/services/common/base-service';
import {
  CalculateEmberData,
  ReportingData,
  SettingsSmartAirData,
  UpdateSettingsData,
} from '@/view-models/smart-air-model';

export default class SmartAirService extends BaseService {
  constructor(axios: AxiosInstance, baseUrl: string) {
    super('SmartAir', axios, 'api', baseUrl);
  }

  /**
   * Returns settings configuration
   * @returns {Promise<SettingsSmartAirData>}
   * @param request: assetkey
   */
  public async getSettingsData(
    assetKey: string
  ): Promise<SettingsSmartAirData> {
    return this.httpClient.get<SettingsSmartAirData>(`heater/${assetKey}`);
  }

  /**
   * Updates the settings configuration
   * @returns {Promise<SettingsSmartAirData>}
   * @param request: assetkey
   */
  public async saveSettingChanges(
    changes: UpdateSettingsData
  ): Promise<UpdateSettingsData> {
    return this.httpClient.put<UpdateSettingsData>('', changes);
  }

  /**
   * Returns the reporting ember data for particular zone
   * @returns {Promise<SettingsSmartAirData>}
   * @param request: assetkey
   */
  public async loadReportingData(
    changes: CalculateEmberData
  ): Promise<ReportingData> {
    return this.httpClient.post<ReportingData>('embercalculateddata', changes);
  }
}
