import { getAuthToken } from '@/services/auth/auth';
import { LogHeader } from '@/view-models/smart-air-model';
import { AppStore } from '@/store/appStore/appStore';

const serviceInterceptor = async (config: any) => {
  const authorization = 'Authorization';
  const correlationId = 'OnPoint-Correlation-Id';
  const metaData = 'OnPoint-MetaData';
  const timeStamp = AppStore.state && AppStore.state.actionTime ? AppStore.state.actionTime : '';
  const logDetails : LogHeader = {
    customerId: AppStore.state && AppStore.state.customerAssetDetails ?
                  AppStore.state.customerAssetDetails.customerId : '',
    assetId: AppStore.state && AppStore.state.customerAssetDetails ? AppStore.state.customerAssetDetails.assetId : '',
    assetName: AppStore.state && AppStore.state.customerAssetDetails ? AppStore.state.customerAssetDetails.assetName : '',
  };
  const token = await getAuthToken();
  // Do not add auth token when uploading direct to S3
  const tokenData: any = JSON.parse(atob(token.split('.')[1]));
  const userId = tokenData['https://auth.onpointsolutions.com/claims/userKey'];
  if (config.url.indexOf('s3.amazonaws.com') > -1) {
    return config;
  } else {
    config.headers[authorization] = `Bearer ${token}`;
    config.headers[correlationId] = `SAR|${userId}|${timeStamp}`;
    config.headers[metaData] = btoa(JSON.stringify(logDetails));
    return config;
  }
};

export default serviceInterceptor;
