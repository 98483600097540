









import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import { Component, Prop, Watch } from 'vue-property-decorator';
import {ButtonPlugin} from 'bootstrap-vue/esm/components/button';
import { authGuard } from '@/services/auth/auth';
import EventBus from '@/shared/event-bus';
import moment from 'moment';
import i18n from '@/i18n';
import { ModalPlugin } from 'bootstrap-vue/esm/components/modal';
import { DropdownPlugin } from 'bootstrap-vue/esm/components/dropdown';
import {FormCheckboxPlugin} from 'bootstrap-vue/esm/components/form-checkbox';
import Loading from '@/components/common/Loading.vue';
import Home from '@/components/home/Home.vue';
import { appRouteBase } from './utils/from-parent';
import { format } from 'timeago.js';
import AppStore from '@/store/appStore/appStore';
import SettingsStore from '@/store/settingsStore/settingsStore';
import HelperMethods from './shared/helper-methods';
import { LogLevel } from '@/enums/log-level';
import { elasticRum } from '@/plugins/elastic-rum';

(async function initializeApp() {
  elasticRum();
})();

Vue.config.errorHandler = (err, vm , info) => {
  HelperMethods.catch(LogLevel.Error,err, vm, info);
};

Vue.config.warnHandler = (err, vm , info) => {
  HelperMethods.catch(LogLevel.Warn, err, vm, info);
};

Vue.use(VueRouter);
Vue.use(ModalPlugin);
Vue.use(ButtonPlugin);
Vue.use(DropdownPlugin);
Vue.use(FormCheckboxPlugin);

Vue.filter('formatShort', (value: number) => {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY  hh:mm a');
  }
});

Vue.filter('formatDateWithTimeAgo', (value: number) => {
  if (!value) {
    return undefined;
  } else {
    if (String(new Date(value)) === 'Invalid Date') {
      return 'N/A';
    }
    const date = moment(String(value));
    if (moment().diff(date, 'days') <= 36532423432) {
      return format(value, 'en_US');
    } else {
      return date.format('MM/DD/YY h:mm a');
    }
  }
});

const routes = [{ path: '*', component: Home, name: 'Home' }];

export const SmartAirRecommendationsRouter = new VueRouter({
  mode: 'history',
  routes
});

SmartAirRecommendationsRouter.beforeEach(authGuard);

@Component({
  name: 'app',
  router: SmartAirRecommendationsRouter,
  components : { Home, Loading },
  i18n
})
export default class App extends Vue {
  @Prop({ required: true })
  public assetkey!: string;

  @Prop({required: true, default: ''})
  public timestamp!: string;

  @Prop({required: true, default: ''})
  public zonekey!: string;

  @Prop({ required: true, default: '' })
  public customerAssetDetails!: string;

  private appStore = AppStore;
  private settingsStore = SettingsStore;

  private deactivated() {
    this.$destroy();
    this.appStore.updateIsLoading(false);
    if ((window as any).eftEventBus !== null && (window as any).eftEventBus !== undefined) {
      (window as any).eftEventBus.$off('onpoint::routeChange',this.handleRouteChange);
    }
  }

  private async mounted() {
   this.settingsStore.setAssetKey(this.assetkey);
   this.settingsStore.setCurrentZoneKey(this.zonekey);
   this.onCustomerOrAssetChange();
   if ((window as any).eftEventBus !== null && (window as any).eftEventBus !== undefined) {
      (window as any).eftEventBus.$on('onpoint::routeChange',this.handleRouteChange);
    }
  }

  private created() {
   this.clearEventBus();
   this.$router.afterEach(() => {
     this.appStore.updateIsLoading(false);
   });
   EventBus.$on('smartair', () => {
      this.$emit('closesmartair', true);
    });
   }

  public handleRouteChange(parentRoute: Route) {
    if (parentRoute.path.startsWith(appRouteBase())) {
      this.appStore.updateIsLoading(false);
      const currentRelativeRoute = parentRoute.path.replace(
        appRouteBase(),
        '/'
      );
      if (currentRelativeRoute !== this.$route.path) {
        this.$router.push(currentRelativeRoute);
      }
    }
  }

  private clearEventBus() {
     EventBus.$off();
  }

  get isLoading() {
     return this.appStore.getIsLoading;
  }

   // Watchers
  @Watch('assetkey')
  private onAssetKeyChange() {
    this.settingsStore.setAssetKey(this.assetkey);
  }
  @Watch('timestamp', {​ immediate: true }​)
  public onEmberRun(): void {
    EventBus.$emit('emitTimestamp', true);
  }
  @Watch('zonekey', {​ immediate: true }​)
  public onZoneRun(): void {
    this.settingsStore.setCurrentZoneKey(this.zonekey);
  }
  @Watch('customerAssetDetails', {​ immediate: true, deep: true }​)
  public onCustomerOrAssetChange(): void {
   if (this.customerAssetDetails) {
     this.appStore.setCustomerAssetDetails(JSON.parse(this.customerAssetDetails));
    }
  }
}
