import { AxiosInstance } from 'axios';
import BaseService from '@/services/common/base-service';

export default class LoggerService extends BaseService {
  constructor(axios: AxiosInstance, baseUrl: string) {
    super('SmartAir', axios, 'api', baseUrl);
  }

  public async logError(error: any): Promise<void> {
    try {
      await this.httpClient.post<void>('addlog', error);
    } catch (err) {
      errorHandler(err);
    }
  }
}

const errorHandler = (error: any): void => {
  // eslint-disable-next-line no-console
  console.error(error);
};