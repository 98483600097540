// Function to convert an enum of colors into an arry

export function colorStore(colorCollection: object) {
    const arrayObjects = [];
    for (const [propertyKey, propertyValue] of Object.entries(
        colorCollection
    )) {
        if (!Number.isNaN(Number(propertyKey))) {
            continue;
        }
        arrayObjects.push(propertyValue);
    }
    return arrayObjects;
}
