
















import { Component } from 'vue-property-decorator';
import HeaterSettingsDialog from '@/components/heaterSettingsDialog/HeaterSettingsDialog.vue';
import EventBus from '@/shared/event-bus';
import SettingsStore from '@/store/settingsStore/settingsStore';
import BaseComponent from '../common/BaseComponent';
import { TransactionName } from '@/enums/transaction-name';

@Component({
  name: 'Home',
  components: { HeaterSettingsDialog },
})
export default class Home extends BaseComponent {
  public isSmartAirVisible = true;
  private settingStore = SettingsStore;

  public toggleSmartAir(): void {
    if (this.settingStore.unsavedChanges) {
      EventBus.$emit('confirmationModal', true);
    } else {
    this.isSmartAirVisible = !this.isSmartAirVisible;
    this.clearData();
    EventBus.$emit('smartair', this.isSmartAirVisible);
    }
  }

  public created() {
    this.startTransaction(TransactionName.InitialPageLoad);
    EventBus.$on('alertClose',() => {
      this.isSmartAirVisible = false;
      this.clearData();
      EventBus.$emit('smartair', this.isSmartAirVisible);
    });
    EventBus.$on('closeSmartAir', () => {
      this.toggleSmartAir();
      this.stopTransaction(TransactionName.CloseSmartAirPopup);
      });
  }

  private clearData() {
    this.settingStore.setReportingData(null);
    this.settingStore.setSettingsData(null);
    this.settingStore.setZoneName(null);
    this.settingStore.setUnsavedChanges(false);
  }
}
