






















































































































































































































import { colorCollection } from '@/enums/color-collection';
import {
  SmartAirDisplayValues,
  SmartAirMethodToUse,
  SmartAirProjectedNames,
  SmartAirSelections,
} from '@/enums/smart-air-selections';
import { LogType } from '@/enums/log-type';
import EventBus from '@/shared/event-bus';
import SettingsStore from '@/store/settingsStore/settingsStore';
import AppStore from '@/store/appStore/appStore';
import { colorStore } from '@/utils/color-utils';
import { methodNames } from '@/utils/method-names-generation';
import {
  AddLog,
  CalculateEmberData,
  ReportingDataSet,
  ReportingMethod,
  SettingsData,
  UpdateSettingsData,
  ISmartAirMethodDetails,
  ColorCollection,
} from '@/view-models/smart-air-model';
import VueMarkdown from 'vue-markdown';
import { Component } from 'vue-property-decorator';
import helperMethods from '@/shared/helper-methods';
import { LogLevel } from '@/enums/log-level';
import Dropdown from '@/components/common/Dropdown.vue';
import BaseComponent from '../common/BaseComponent';
import { TransactionName } from '@/enums/transaction-name';

@Component({
  name: 'heater-settings-dialog',
  components: {
    Alert: () => import('@/components/common/Alert.vue'),
    ConfirmationModal: () =>
      import('@/components/common/ConfirmationModal.vue'),
    VueMarkdown,
    Loading : () => import('@/components/common/Loading.vue'),
    Dropdown
  },
})
export default class HeaterSettingsDialog extends BaseComponent {
  // VUE.JS Props

  // VUEX

  // Properties
  public isWarningVisible = false;
  public alertMessage: string = '';
  public isAlertOpen = true;
  public colorCollection: ColorCollection[] | any = [];
  public methodCollection: ISmartAirMethodDetails[] = [];
  public selectedZone: string = '';
  public modalTitle: string = '';
  public modalContent: string = '';
  public tabledata: ReportingMethod[] | any = [];
  public defaultSettingName: string = '';
  public projectedSettings: ReportingDataSet | any = {};
  public currentSettings: ReportingDataSet | any = {};
  public defaultSettingData: string = '';
  public newSettingsData: string = '';
  public showConfirmationModal = false;
  public disableBtn = true;
  public isInternalWarning = false;
  public selectedZoneData: any = {};
  public settingsSaved = false;
  public differentZone = false;
  public originalData: ReportingMethod[] | any = [];
  public isConservativeO2Checked = false;
  public defaultSettinsNameCopy: string = '';
  public projectedMethodColorIndicator: string = '';
  public saveConfirmationFlag = true;
  public isReportingApiActive = false;
  public closePopup = false;
  public saveZoneData: SettingsData | any = null;
  public dropdownPlaceholder: string = '';

  private currentSelectedMethod: string = '';
  private settingStore = SettingsStore;
  private smartAirSelections = SmartAirSelections;
  private smartAirMethodToUse: any = SmartAirMethodToUse;
  private smartAirDisplayValues: any = SmartAirDisplayValues;
  private smartAirProjectedNames: any = SmartAirProjectedNames;
  private appStore = AppStore;
  // Fields

  // Getters
  public get isLoading() {
    return this.settingStore.getIsLoading;
  }

  public get getSettingsData() {
    return this.settingStore.settingsData;
  }

  public get reportingData() {
    return this.settingStore.reportingData;
  }

  public get unsavedChanges() {
    return this.settingStore.unsavedChanges;
  }

  public get currentZoneName() {
    if (this.settingStore.settingsData && this.settingStore.currentZoneKey) {
        const selectedZoneInfo = this.settingStore.settingsData.settingsData.filter(
                  (item : any) => item.zoneIdentifier === this.settingStore.currentZoneKey
        );
      return selectedZoneInfo[0].name;
    }
  }

  public closeConfirmationModal() {
    this.appStore.updateActionTime();
    if (this.differentZone) {
      this.loadZoneData(this.saveZoneData);
      this.settingStore.loadSettings(this.settingStore.assetkey);
    }
    this.differentZone = false;
    this.defaultSettingName = this.defaultSettinsNameCopy;
    if (
      this.defaultSettingName === this.smartAirDisplayValues.MostConservative
    ) {
      this.defaultSettingName = this.setDefaultMethod(
        this.defaultSettingName,
        this.reportingData.mostConservativeO2Method
      );
      this.defaultSettingData = this.defaultSettingName;
    } else {
      this.defaultSettingData = this.setDefaultMethod(
        this.defaultSettinsNameCopy,
        ''
      );
      this.handleMostConservativeSwitch(false);
    }
    this.tabledata = this.originalData.filter(
      (item: ReportingMethod) =>
        item.name === this.smartAirDisplayValues[this.defaultSettingName]
    )[0];
    this.projectedMethodColorIndicator = this.setColorIndicator(
      this.defaultSettingName
    );
    this.currentSettings = this.getCurrentSettings(
      this.smartAirDisplayValues[this.defaultSettingName]
    );
    this.projectedSettings = this.getProjectedSettings(
      this.smartAirDisplayValues[this.defaultSettingName]
    );
    this.settingStore.setUnsavedChanges(false);
    this.appStore.updateActionTime();
    this.handleProjectedError();
    this.disableBtn = true;
    this.toggleConfirmationModal();
    if (this.closePopup) {
      EventBus.$emit('alertClose', true);
      this.closePopup = false;
    }
  }

  public closeSmartAir(): void {
     this.startTransaction(TransactionName.CloseSmartAirPopup);
     EventBus.$emit('closeSmartAir', true);
  }

  // Lifecycle Handlers
  public created() {
    EventBus.$on('closeWarning', this.closeWarning);
    EventBus.$on('emitTimestamp', this.onEmberRun);
    EventBus.$on('confirmationModal', this.closeModal);
    this.colorCollection = colorStore(colorCollection);
    this.methodCollection = methodNames();
  }
  public mounted() {
    this.dropdownPlaceholder = this.$t('smartAirRecommendations.dropdownPlaceholder') as string;
    this.startTransaction(TransactionName.LoadSettingsData);
    this.loadSettingsData();
  }

  public loadSettingsData() {
    this.appStore.updateActionTime();
    this.settingStore.updateIsLoading(true);
    this.settingStore
      .loadSettings(this.settingStore.assetkey)
      .then((res) => {
        this.stopTransaction(TransactionName.LoadSettingsData);
        if (!this.isReportingApiActive) {
          this.appStore.updateActionTime();
          this.settingStore.updateIsLoading(false);
        }
        if (res.settingsData && res.settingsData.length > 0) {
          this.isWarningVisible = false;
          this.appStore.updateActionTime();
          this.isAlertOpen = false;
          if (this.settingStore.zoneName) {
            const selectedZoneInfo = res.settingsData.filter(
              (item) => item.name === this.settingStore.zoneName
            );
            this.defaultSettingName = selectedZoneInfo[0].methodToUse;
            this.selectedZoneData = selectedZoneInfo[0];
          } else {
            if (this.settingStore.currentZoneKey) {
              const selectedZoneInfo = res.settingsData.filter(
                  (item : any) => item.zoneIdentifier === this.settingStore.currentZoneKey
              );
              this.defaultSettingName = selectedZoneInfo && selectedZoneInfo[0].methodToUse;
              this.selectedZoneData = selectedZoneInfo && selectedZoneInfo[0];
            } else {
              this.defaultSettingName = res.settingsData[0].methodToUse;
              this.selectedZoneData = res.settingsData[0];
            }
          }
          if (!this.settingStore.reportingData) {
            this.reportingDataApi();
          }
          this.defaultSettinsNameCopy = this.defaultSettingName;
        } else {
          this.appStore.updateActionTime();
          this.isWarningVisible = true;
          this.isAlertOpen = true;
          this.alertMessage = this.$t(
            'smartAirRecommendations.emptySettingsFile'
          ) as string;
        }
      })
      .catch((err: any) => {
        this.appStore.updateActionTime();
        helperMethods.catch(
          LogLevel.Error,
          err,
          'heater-settings-dialog',
          'loadSettings'
        );

        this.isWarningVisible = true;
        this.isAlertOpen = true;
        this.alertMessage = this.$t(
          'smartAirRecommendations.settingsFileLoadError'
        ) as string;
        this.settingStore.updateIsLoading(false);
      });
  }

  // Private Methods

  // Helper Methods

  // Setting color for each projected method
  private setColorIndicator(methodName: string): string {
    let projectedMethodColorIndicator = '';
    switch (methodName) {
      case this.smartAirMethodToUse.MeasuredO2:
        projectedMethodColorIndicator = this.colorCollection[0];
        break;
      case this.smartAirMethodToUse.measuredFuelFlow:
        projectedMethodColorIndicator = this.colorCollection[1];
        break;
      case this.smartAirMethodToUse.fuelPressure:
        projectedMethodColorIndicator = this.colorCollection[2];
        break;
    }
    return projectedMethodColorIndicator;
  }

  // Function to retrieve the projected settings for the selected method
  private getProjectedSettings(methodName: string): object {
    return this.tabledata[
      this.smartAirProjectedNames[methodName] + 'Projected'
    ];
  }

  // Function to retrieve the current settings for the selected method
  private getCurrentSettings(methodName: string): object {
    return this.tabledata[this.smartAirProjectedNames[methodName] + 'Current'];
  }

  // Function to show the confirmation modal with proper content and title
  private setModalContent(title: string, content: string): void {
    this.modalContent = content;
    this.modalTitle = title;
  }

  // Function to handle the toggle switch
  private handleMostConservativeSwitch(state: boolean): void {
    this.isConservativeO2Checked = state;
  }

  // Function to select the default method on load
  private setDefaultMethod(method: string, conservativeMethod: string): string {
    let defaultSettingName = method;
    if (method === this.smartAirDisplayValues.MostConservative) {
      this.handleMostConservativeSwitch(true);
      defaultSettingName = conservativeMethod;
    }
    return defaultSettingName;
  }

  // Function to handle projected error
  private handleProjectedError(): void {
    if (this.tabledata && this.tabledata.projectedBelowTargetFlag){
      this.isInternalWarning = true;
      this.alertMessage = this.$t('smartAirRecommendations.limitedAirLabel') as string;
    }
    else if (
      this.projectedSettings.lowValue < this.reportingData.targetO2) {
      this.isInternalWarning = true;
      this.alertMessage = this.$t(
        'smartAirRecommendations.targetO2ProjectedError'
      ) as string;
    }
      else if (!this.projectedSettings.highValue &&
        !this.projectedSettings.lowValue) {
        this.isInternalWarning = true;
        this.alertMessage = this.$t('smartAirRecommendations.zoneDataUnavailable') as string;
        }
      else if (!this.reportingData.targetO2) {
        this.isInternalWarning = true;
         this.alertMessage = this.$t('smartAirRecommendations.targetO2Unavailable') as string;
        }
    else {
      this.isInternalWarning = false;
    }
  }

  // Event Methods

  private toggleConfirmationModal(): void {
    this.showConfirmationModal = !this.showConfirmationModal;
  }
  private closeModal(): void {
    this.closePopup = true;
    this.toggleConfirmationModal();
  }

  // Handling value selection in zone dropdown
  public selectCombustionZone(data: SettingsData): void {
    this.startTransaction(TransactionName.ZoneChange);
    if (
      data.name !== this.settingStore.zoneName &&
      this.settingStore.unsavedChanges
    ) {
      this.appStore.updateActionTime();
      this.saveZoneData = data;
      this.differentZone = true;
      this.isInternalWarning = false;
      this.setModalContent( this.$t('smartAirRecommendations.confirmChange') as string, '');
      this.toggleConfirmationModal();
    } else {
      this.isConservativeO2Checked = false;
      this.loadZoneData(data);
    }
    this.stopTransaction(TransactionName.ZoneChange);
  }

  // Function to handle the combustion zone data
  private loadZoneData(data: SettingsData): void {
    this.startTransaction(TransactionName.LoadZone);
    this.newSettingsData = this.smartAirDisplayValues[data.methodToUse];
    this.defaultSettingName = data.methodToUse;
    this.settingStore.setZoneName(data.name);
    this.defaultSettinsNameCopy = this.defaultSettingName;
    this.selectedZoneData = data;
    this.isConservativeO2Checked =
      this.defaultSettingName === this.smartAirDisplayValues.MostConservative
        ? true
        : false;
    this.reportingDataApi();
    this.appStore.updateActionTime();
    this.stopTransaction(TransactionName.InitialPageLoad);
  }

  public saveConfirmation() {
    if (this.closePopup) {
      EventBus.$emit('alertClose', true);
    }
    this.differentZone = false;
    this.isInternalWarning = false;
    this.saveConfirmationFlag = false;
    this.toggleConfirmationModal();
    this.saveSettingChanges();
    this.appStore.updateActionTime();
  }
  public saveSettingChanges(): void {
    this.setModalContent('', '');
    if (this.differentZone || this.isInternalWarning) {
      if (!this.projectedSettings.highValue &&
        !this.projectedSettings.lowValue) {
        this.setModalContent(this.$t('smartAirRecommendations.unavailablityLabel') as string, this.$t('smartAirRecommendations.dataUnavailableConfirmation') as string);
      }
      else if (!this.reportingData.targetO2){
        this.setModalContent(this.$t('smartAirRecommendations.unavailablityLabel') as string, this.$t('smartAirRecommendations.targetO2Confirmation') as string);
      }
      this.toggleConfirmationModal();
    } else {
      this.updateSettings();
    }
  }

  // Function to update settings data and handle scenarios on updation
  private updateSettings() {
    this.startTransaction(TransactionName.SaveMethod);
    const newData: UpdateSettingsData = {
      assetKey: this.settingStore.assetkey,
      zoneKey: this.selectedZoneData.zoneIdentifier,
      methodToUse: this.isConservativeO2Checked
        ? this.smartAirDisplayValues.MostConservative
        : this.newSettingsData,
    };
    const previousSelectedMethod = this.currentSelectedMethod ? this.currentSelectedMethod : this.defaultSettinsNameCopy === this.smartAirDisplayValues.MostConservative
        ? this.smartAirDisplayValues.MostConservativeO2 : this.methodCollection.filter((item: any) => item.methodCode === this.defaultSettinsNameCopy)[0]?.methodName;
    this.currentSelectedMethod = this.isConservativeO2Checked
        ? this.smartAirDisplayValues.MostConservativeO2 : this.methodCollection.filter((item: any) => item.methodCode === this.newSettingsData)[0]?.methodName;

    const log: AddLog = {
      entityAncestorKey: this.settingStore.assetkey,
      entityKey: this.settingStore.assetkey,
      entityType: LogType.Asset,
      logType: LogType.System,
      text: `\nZone Name - **${this.selectedZoneData.name}**\n+ Airside recommendations method changed from **${ previousSelectedMethod }** to **${ this.currentSelectedMethod }**`,
      parentEntityKey: this.settingStore.assetkey
    };
    this.settingStore.updateIsLoading(true);
    this.settingStore
      .saveSettings(newData)
      .then(() => {
        this.stopTransaction(TransactionName.SaveMethod);
        this.appStore.updateActionTime();
        this.settingStore.addAssetLog(log);
        if (this.saveZoneData) {
          this.loadZoneData(this.saveZoneData);
        }
        this.loadSettingsData();
        if (
          (this.saveConfirmationFlag && this.isInternalWarning) ||
          this.isConservativeO2Checked
        ) {
          this.reportingDataApi();
        } else {
          this.defaultSettingData = this.newSettingsData;
        }
        this.differentZone = false;
        this.toggleConfirmationModal();
        this.disableBtn = true;
        this.settingsSaved = true;
        this.isInternalWarning = false;
        this.saveConfirmationFlag = true;
        if (!this.isReportingApiActive) {
          this.settingStore.updateIsLoading(false);
        }
        this.settingStore.setUnsavedChanges(false);
        this.saveZoneData = null;
        this.setModalContent(
          this.$t('smartAirRecommendations.settingsSaveSuccess') as string,
          this.$t('smartAirRecommendations.settingsSaveSuccessMsg') as string
        );
      })
      .catch((err: any) => {
        this.stopTransaction(TransactionName.SaveMethod);
        this.appStore.updateActionTime();
          helperMethods.catch(
            LogLevel.Error,
            err,
            'heater-settings-dialog',
            'updateSettings'
          );
        this.toggleConfirmationModal();
        this.settingStore.updateIsLoading(false);
        this.setModalContent(
          this.$t('smartAirRecommendations.settingsSaveError') as string,
          this.$t('smartAirRecommendations.settingsSaveErrorMsg') as string
        );
      });
  }
  public closeWarning(): void {
    this.isAlertOpen = false;
    this.isInternalWarning = false;
  }

  // Function to handle all the cases on change of method (radio button)
  public handleMethodChange(data: any): void {
    this.startTransaction(TransactionName.MethodChange);
    this.appStore.updateActionTime();
    this.defaultSettingName = this.smartAirMethodToUse[data];
    this.newSettingsData = this.smartAirMethodToUse[data];
    this.handleMostConservativeSwitch(false);
    if (this.originalData.length > 0) {
      this.projectedMethodColorIndicator = this.setColorIndicator(
        this.newSettingsData
      );
      this.tabledata = this.originalData.filter(
        (item: ReportingMethod) => item.name === data
      )[0];
      if (this.tabledata) {
        this.projectedSettings = this.getProjectedSettings(this.tabledata.name);
        this.currentSettings = this.getCurrentSettings(this.tabledata.name);
      }
      if (
        this.tabledata &&
        this.tabledata.fuelPressureCurrent &&
        this.tabledata.fuelPressureProjected &&
        this.tabledata.measuredFuelFlowCurrent &&
        this.tabledata.measuredFuelFlowProjected
      ) {
        this.handleProjectedError();
      }
      if (
        this.newSettingsData === this.defaultSettingData &&
        !this.settingsSaved
      ) {
        this.disableBtn = true;
        this.settingStore.setUnsavedChanges(false);
      } else {
        this.settingStore.setUnsavedChanges(true);
        this.disableBtn = false;
        this.settingsSaved = false;
      }
      if (
        this.smartAirMethodToUse[data] === this.defaultSettingData &&
        this.selectedZoneData.methodToUse ===
          this.smartAirDisplayValues.MostConservative &&
        !this.isConservativeO2Checked
      ) {
        this.settingStore.setUnsavedChanges(true);
        this.disableBtn = false;
      }
    } else {
      this.isInternalWarning = true;
      this.alertMessage = this.$t(
        'smartAirRecommendations.selectionUnavailable'
      ) as string;
      this.disableBtn = true;
      this.settingStore.setUnsavedChanges(false);
    }
    this.stopTransaction(TransactionName.MethodChange);
  }
  public onEmberRun(): void {
    if (this.settingStore.settingsData) {
      this.startTransaction(TransactionName.EmberRunTriggered);
      this.appStore.updateActionTime();
      this.reportingDataApi();
      this.stopTransaction(TransactionName.EmberRunTriggered);
    }
  }

  // Function to call reporting data and handle other scenarios
  private reportingDataApi() {
    this.startTransaction(TransactionName.ReportingDataCall);
    this.appStore.updateActionTime();
    this.isReportingApiActive = true;
    this.settingStore.updateIsLoading(true);
    const reportingRequest: CalculateEmberData = {
      assetKey: this.settingStore.assetkey,
      zoneIdentifier: this.selectedZoneData.zoneIdentifier,
    };
    this.settingStore
      .loadReportingData(reportingRequest)
      .then((res) => {
        this.stopTransaction(TransactionName.LoadZone);
        this.stopTransaction(TransactionName.ReportingDataCall);
        this.settingStore.updateIsLoading(false);
        this.isReportingApiActive = false;
        if (
          Object.keys(res).length > 0 &&
          this.defaultSettingName ===
            this.smartAirDisplayValues.MostConservative
        ) {
          this.defaultSettingName = this.setDefaultMethod(
            this.defaultSettingName,
            res && res.mostConservativeO2Method
          );
          this.defaultSettingData = this.defaultSettingName;
        } else {
          this.defaultSettingData = this.setDefaultMethod(
            this.defaultSettinsNameCopy,
            ''
          );
        }
        this.projectedMethodColorIndicator = this.setColorIndicator(
          this.defaultSettingName
        );
        if (res.smartAirResults) {
          const myArr = res.smartAirResults;
          this.originalData = myArr;
          this.tabledata = myArr.filter(
            (item : any) =>
              item.name === this.smartAirDisplayValues[this.defaultSettingName]
          )[0];
          if (this.tabledata) {
            this.projectedSettings = this.getProjectedSettings(
              this.tabledata.name
            );
            this.handleProjectedError();
          }
          this.disableBtn = this.unsavedChanges ? false : true;
        } else {
          this.tabledata = {};
          this.isInternalWarning = true;
          this.alertMessage = this.$t(
            'smartAirRecommendations.reportingDataLoadError'
          ) as string;
        }
      })
      .catch((err) => {
        if (err) {
            helperMethods.catch(
              LogLevel.Error,
              err,
              'heater-settings-dialog',
              'loadReportingData'
            );
          this.settingStore.updateIsLoading(false);
          this.isInternalWarning = true;
          this.alertMessage = this.$t(
            'smartAirRecommendations.reportingDataLoadError'
          ) as string;
        }
      });
  }
  // Emitters
}
