import {
  Module,
  VuexModule,
  getModule,
  Mutation,
  Action,
} from 'vuex-module-decorators';
import store from '@/store';
import { ISettingsStore } from '@/store/types/settings';
import sharedAxiosInstance from '@/services/common/api-service';
import ConfigFactory from '@/services/config/config';
import SmartAirService from '@/services/smart-air-service';
import {
  AddLog,
  CalculateEmberData,
  ReportingData,
  SettingsSmartAirData,
  UpdateSettingsData,
} from '@/view-models/smart-air-model';
import LogService from '@/services/logs-service';

@Module({
  namespaced: true,
  name: 'settingsStore',
  store,
  dynamic: true,
})
export class SettingsStore extends VuexModule implements ISettingsStore {
  // State
  public isLoading: boolean = true;
  public settingsData: SettingsSmartAirData | any = null;
  public assetkey: string = '';
  public reportingData: ReportingData | any = null;
  public zoneName: string = '';
  public unsavedChanges: boolean = false;
  public currentZoneKey: string = '';

  // Getters
  public get getIsLoading(): boolean {
    return this.isLoading;
  }

  // Mutations
  @Mutation
  public updateIsLoading(newIsLoading: boolean): void {
    this.isLoading = newIsLoading;
  }

  @Mutation
  public setSettingsData(newData: SettingsSmartAirData | null): void {
    this.settingsData = newData;
  }

  @Mutation
  public setAssetKey(key: string): void {
    this.assetkey = key;
  }

  @Mutation
  public setReportingData(reportingData: ReportingData | null): void {
    this.reportingData = reportingData;
  }

  @Mutation
  public setZoneName(name: string | any): void {
    this.zoneName = name;
  }

  @Mutation
  public setCurrentZoneKey(zoneKey: string): void {
    this.currentZoneKey = zoneKey;
  }

  @Mutation
  public setUnsavedChanges(change: boolean): void {
    this.unsavedChanges = change;
  }

  @Action({ rawError: true })
  public async loadSettings(assetKey: string): Promise<SettingsSmartAirData> {
    const smartAirService = await initSmartAirService();
    const settings = await smartAirService.getSettingsData(assetKey);
    settings.settingsData.sort((a,b) => a.sortOrder - b.sortOrder);
    this.setSettingsData(settings);
    return settings;
  }

  @Action({ rawError: true })
  public async saveSettings(
    request: UpdateSettingsData
  ): Promise<UpdateSettingsData> {
    const smartAirService = await initSmartAirService();
    return smartAirService.saveSettingChanges(request);
  }

  @Action({ rawError: true })
  public async loadReportingData(request: CalculateEmberData
  ): Promise<ReportingData> {
    const smartAirService = await initSmartAirService();
    const reportingData = await smartAirService.loadReportingData(
      request
    );
    this.setReportingData(reportingData);
    return reportingData;
  }

  @Action({ rawError: true })
  public async addAssetLog(log: AddLog
  ): Promise<void> {
    const entityLogsService = await initEntityLogsService();
    entityLogsService.addLogs(
      log
    );
  }
}

const initSmartAirService = async () => {
  const conf = await ConfigFactory.GetConfig();
  return new SmartAirService(sharedAxiosInstance, conf.get('smartAirApiUrl'));
};

const initEntityLogsService = async () => {
  const conf = await ConfigFactory.GetConfig();
  return new LogService(sharedAxiosInstance, conf.get('entityLogsApiUrl'));
};

let module: SettingsStore = getModule(SettingsStore, store);
export const setSettingsModule = (newModule: SettingsStore) => (module = newModule);
export default module;
