import sharedAxiosInstance from '@/services/common/api-service';
import ConfigFactory from '@/services/config/config';
import LoggerService from '@/services/logger-service';
import { Log } from '@/view-models/smart-air-model';

export default class HelperMethods{

  public static messageTemplate: string = '';
 /**
   * global Error Call
   * @param error
   * @returns {void}
   */
  public static async catch(logType: string, error: any, method?: any, information?: any): Promise<void> {

    // eslint-disable-next-line no-console
    if (typeof(method) === typeof(logType)) {
      this.messageTemplate = method;
    } else {
      this.messageTemplate = method.$options._componentTag;
    }
    const request: Log = {
      logLevel: logType,
      messageTemplate: `${this.messageTemplate} - ${information}`,
      logDetails: error.response ?  JSON.stringify(error.response) : error.stack ? error.stack : error
    };

   const logger = await initLoggerService();
   await logger.logError(request).catch(err => console.log('Unable to log the required issue!', err));
  }
}

const initLoggerService = async () => {
  const conf = await ConfigFactory.GetConfig();
  return new LoggerService(sharedAxiosInstance, conf.get('smartAirApiUrl'));
};