// Function to predefined method names, and their key values

export function methodNames() {
    return [
        {
            methodName: 'Measured O<sub>2</sub> & Air Flow Model',
            methodValue: 'MeasuredO2FixedAir',
            methodCode: 'MeasuredO2FixedAir',
            currentValueDisplayKeyName: 'measuredO2FixedAirCurrent',
            projectedValueDisplayKeyName: 'measuredO2FixedAirProjected'
        },
        {
            methodName: 'Measured O<sub>2</sub> & Fuel Side Model',
            methodValue: 'MeasuredO2FixedFuel',
            methodCode: 'MeasuredO2FixedFuel',
            currentValueDisplayKeyName: 'measuredO2FixedFuelCurrent',
            projectedValueDisplayKeyName: 'measuredO2FixedFuelProjected'
        },
        {
            methodName: 'Measured Fuel Flow & Air Flow Model',
            methodValue: 'MeasuredFuelFlow',
            methodCode: 'CustomerFlow',
            currentValueDisplayKeyName: 'measuredFuelFlowCurrent',
            projectedValueDisplayKeyName: 'measuredFuelFlowProjected'
        },
        {
            methodName: 'Measured Fuel Pressure & Air Flow Model',
            methodValue: 'FuelPressure', methodCode: 'CleanTip',
            currentValueDisplayKeyName: 'fuelPressureCurrent',
            projectedValueDisplayKeyName: 'fuelPressureProjected'
        },
    ];
}
