import { AddLog } from '@/view-models/smart-air-model';
import { AxiosInstance } from 'axios';
import BaseService from './common/base-service';

export default class LogService extends BaseService  {
  constructor(axios: AxiosInstance, baseUrl: string) {
    super('logs', axios, '/aov', baseUrl);
  }

  /**
   * Add an asset system log.
   * @returns {Promise<Array<AddLog>>}
   * @param log: AddLog
   */
  public async addLogs(log: AddLog) {
    await this.httpClient.post(
      '', log
    );
  }
}
