// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../node_modules/css-loader/dist/cjs.js??ref--8-oneOf-1-1!../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../node_modules/postcss-loader/src/index.js??ref--8-oneOf-1-2!../node_modules/postcss-loader/src/index.js??ref--8-oneOf-1-3!../../CommonLibrary/prod/global.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../node_modules/css-loader/dist/cjs.js??ref--8-oneOf-1-1!../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../node_modules/postcss-loader/src/index.js??ref--8-oneOf-1-2!../node_modules/postcss-loader/src/index.js??ref--8-oneOf-1-3!./assets/invision.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css);"]);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, "#app{height:100%;width:100%}#app,#app .link{background-color:var(--charade);color:var(--alto)}#app .link__app-header-band .nav-link{cursor:pointer;color:var(--color-primary-400)}", ""]);
// Exports
module.exports = exports;
