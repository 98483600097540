



























import { Component, Prop, Emit } from 'vue-property-decorator';
import Vue from 'vue';

@Component({
  name: 'dropdown',
})
export default class Dropdown extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public dropdownItems!: any;

  @Prop({ required: true })
  public defaultSelection!: any;

  @Prop({ required: true })
  public placeholder!: string;

  // VUEX
  // Properties
  public isDropdownVisible = false;
  public selectedItem = '';
  // Getters
  // Lifecycle Handlers
  public created() {
       window.addEventListener('click', this.closeDropdownMenu, true);
  }
  public mounted() {
    if (this.defaultSelection) {
      this.selectedItem = this.defaultSelection;
    }
  }
  public beforeDestroy() {
    window.removeEventListener('click', this.closeDropdownMenu);
  }
  // Event Methods
  public showDropdownMenu() {
    this.isDropdownVisible = !this.isDropdownVisible;
  }

  public closeDropdownMenu(event: any) {
    if (!this.$el.contains(event.target)){
      this.isDropdownVisible = false;
    }
  }
  @Emit('selectDropdownItem')
  public selectDropdownItem(items: any): void {
    this.isDropdownVisible = false;
    this.selectedItem = items.name;
  }
}
