import {
  Module,
  VuexModule,
  getModule,
  Mutation
} from 'vuex-module-decorators';
import store from '@/store';
import { IErrorStore } from '@/store/types/error';

import { ErrorBusMessage, IErrorBusMessage, IErrorMessageConfig } from '@/view-models/error-model';

const EVENT_NAME = 'GLOBAL_ERROR';
const APP_TAG = 'Smart-Air-Recommendations';

@Module({
  namespaced: true,
  name: 'errorStore',
  store,
  dynamic: true,
})

export class ErrorStore extends VuexModule implements IErrorStore {
  // State
  public error: any = null;
  public showModal: boolean = false;

  // Getters
  public get getError() {
    return this.error;
  }

  public get getShowModal() {
    return this.showModal;
  }

  // Mutations
  @Mutation
  public setError(payload: {error: any, config?: IErrorMessageConfig, uiError?: boolean}): void {
    emitError(payload.error, payload.config, payload.uiError);
  }
  @Mutation
  public clearError(): void {
    this.error = null;
    this.showModal = false;
  }
  // Actions
}

let module: ErrorStore = getModule(ErrorStore, store);
export const setErrorModule = (newModule: ErrorStore) => (module = newModule);
export default module;

const emitError = (error: any, config?: IErrorMessageConfig, uiError?: boolean): void => {
  ErrorStore.state.error = error;
  if ((window as any).eftEventBus && !uiError) {
      const emitEvent: IErrorBusMessage = new ErrorBusMessage(APP_TAG, error, config);
      (window as any).eftEventBus.$emit(EVENT_NAME, emitEvent);
  } else {
    ErrorStore.state.showModal = true;
  }
};
