import { AxiosInstance } from 'axios';
import HttpClient from '@/services/common/http-client';

export default class BaseService {
  protected httpClient: HttpClient;

  constructor(
    private readonly base: string,
    private readonly http: AxiosInstance,
    private readonly path: string = 'api',
    private readonly baseUrl: string = ''
  ) {
    this.base = base;
    if (baseUrl) {
      this.path =
        baseUrl + (path != null && typeof path === 'string' ? path : 'api');
    } else {
      this.path = path != null && typeof path === 'string' ? path : 'api';
    }
    this.http = http;
    this.httpClient = new HttpClient(
      this.http,
      `${this.path}${this.base ? `/${this.base}` : ''}`
    );
  }

  protected getUrl(uri: string): string {
    return `${this.path}/${uri}`;
  }
}
