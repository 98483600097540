import {
  Module,
  VuexModule,
  getModule,
  Mutation,
} from 'vuex-module-decorators';
import store from '@/store';
import { IAppStore } from '@/store/types/app';

@Module({
  namespaced: true,
  name: 'appStore',
  store,
  dynamic: true,
})
export class AppStore extends VuexModule implements IAppStore {
  // State
  public isLoading: boolean = true;
  public isStandAlone: boolean = false;
  public actionTime: number =  new Date().getTime();
  public customerAssetDetails = '';

  // Getters
  public get getIsLoading(): boolean {
    return this.isLoading;
  }
  public get getIsStandalone(): boolean {
    return this.isStandAlone;
  }

  // Mutations
  @Mutation
  public updateIsLoading(newIsLoading: boolean): void {
    this.isLoading = newIsLoading;
  }
  @Mutation
  public setIsStandAlone(newIsStandAlone: boolean): void {
    this.isStandAlone = newIsStandAlone;
  }
  @Mutation
  public updateActionTime(): void {
    this.actionTime = new Date().getTime();
  }
  @Mutation
  public setCustomerAssetDetails(details: string): void {
    this.customerAssetDetails = details;
  }
}

let module: AppStore = getModule(AppStore, store);
export const setAppModule = (newModule: AppStore) => (module = newModule);
export default module;
